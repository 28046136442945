import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    meta: {
      middleware: "auth",
    },
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
        meta: {
          pageTitle: "Dashboard",
          breadcrumbs: ["Dashboards"],
        },
      },
      {
        path: "/dashboard-progress",
        name: "dashboard-progress",
        component: () => import("@/views/DashboardProgress.vue"),
        meta: {
          pageTitle: "Dashboard Progress",
          breadcrumbs: ["Dashboards"],
        },
      },
      {
        path: "/profile/change_photo",
        name: "profile-change-photo",
        component: () => import("@/views/ChangePhoto.vue"),
        meta: {
          pageTitle: "Change Photo",
          breadcrumbs: ["Profile", "Change Photo"],
        },
      },
      {
        path: "/profile/change_password",
        name: "profile-change-password",
        component: () => import("@/views/ChangePassword.vue"),
        meta: {
          pageTitle: "Change Password",
          breadcrumbs: ["Profile", "Change Password"],
        },
      },
    ],
  },
  {
    path: "/admin",
    redirect: "/dashboard",
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    meta: {
      middleware: "auth",
    },
    children: [
      {
        path: "/user-management",
        name: "user-management",
        component: () =>
          import("@/views/Superadmin/UserManagement/UserList.vue"),
        meta: {
          pageTitle: "User Management",
          breadcrumbs: ["User Management"],
        },
      },
      {
        path: "/user-management/add",
        name: "add-user",
        component: () =>
          import("@/views/Superadmin/UserManagement/UserAddNew.vue"),
        meta: {
          pageTitle: "User Management",
          breadcrumbs: ["User Management", "Tambah"],
        },
      },
      {
        path: "/user-management/edit/:id",
        name: "edit-user",
        component: () =>
          import("@/views/Superadmin/UserManagement/UserEdit.vue"),
        meta: {
          pageTitle: "User Management",
          breadcrumbs: ["User Management", "Edit"],
        },
      },
      {
        path: "/user-management/change-password/:id",
        name: "changepassword-user",
        component: () =>
          import("@/views/Superadmin/UserManagement/UserChangePassword.vue"),
        meta: {
          pageTitle: "User Management",
          breadcrumbs: ["User Management", "Change Password"],
        },
      },
      {
        path: "/key-stakeholder-master",
        name: "key-stakeholder-master",
        component: () => import("@/views/Superadmin/KSM/KSMList.vue"),
        meta: {
          pageTitle: "Key Stakeholder Master",
          breadcrumbs: ["Key Stakeholder Master"],
        },
      },
      {
        path: "/key-stakeholder-master/add",
        name: "add-ksm",
        component: () => import("@/views/Superadmin/KSM/KSMAddNew.vue"),
        meta: {
          pageTitle: "Key Stakeholder Master",
          breadcrumbs: ["Key Stakeholder Master", "Tambah"],
        },
      },
      {
        path: "/key-stakeholder-master/edit/:id",
        name: "edit-ksm",
        component: () => import("@/views/Superadmin/KSM/KSMEdit.vue"),
        meta: {
          pageTitle: "Key Stakeholder Master",
          breadcrumbs: ["Key Stakeholder Master", "Edit"],
        },
      },
      {
        path: "/list-approval-sementara",
        name: "list-approval-sementara",
        component: () => import("@/views/Superadmin/LAS/LASList.vue"),
        meta: {
          pageTitle: "List Approval Sementara",
          breadcrumbs: ["List Approval Sementara"],
        },
      },
      {
        path: "/list-approval-sementara/add",
        name: "add-las",
        component: () => import("@/views/Superadmin/LAS/LASAddNew.vue"),
        meta: {
          pageTitle: "List Approval Sementara",
          breadcrumbs: ["List Approval Sementara", "Tambah"],
        },
      },
      {
        path: "/list-approval-sementara/edit/:id",
        name: "edit-las",
        component: () => import("@/views/Superadmin/LAS/LASEdit.vue"),
        meta: {
          pageTitle: "List Approval Sementara",
          breadcrumbs: ["List Approval Sementara", "Edit"],
        },
      },
      {
        path: "/upload",
        name: "upload",
        component: () => import("@/views/Superadmin/Upload/UploadList.vue"),
        meta: {
          pageTitle: "Uploaded Document",
          breadcrumbs: ["Upload Document"],
        },
      },
      {
        path: "/upload/uploaded-roadmap/add",
        name: "add-upload-roadmap",
        component: () =>
          import("@/views/Superadmin/Upload/Roadmap/UploadRoadmapAddNew.vue"),
        meta: {
          pageTitle: "Uploaded Document",
          breadcrumbs: ["Upload Document", "Roadmap", "Tambah"],
        },
      },
      {
        path: "/upload/uploaded-roadmap/edit/:id",
        name: "edit-upload-roadmap",
        component: () =>
          import("@/views/Superadmin/Upload/Roadmap/UploadRoadmapEdit.vue"),
        meta: {
          pageTitle: "Uploaded Document",
          breadcrumbs: ["Upload Document", "Roadmap", "Edit"],
        },
      },
      {
        path: "/upload/uploaded-rkap/add",
        name: "add-upload-rkap",
        component: () =>
          import("@/views/Superadmin/Upload/RKAP/UploadRKAPAddNew.vue"),
        meta: {
          pageTitle: "Uploaded Document",
          breadcrumbs: ["Upload Document", "RKAP", "Tambah"],
        },
      },
      {
        path: "/upload/uploaded-rkap/edit/:id",
        name: "edit-upload-rkap",
        component: () =>
          import("@/views/Superadmin/Upload/RKAP/UploadRKAPEdit.vue"),
        meta: {
          pageTitle: "Uploaded Document",
          breadcrumbs: ["Upload Document", "RKAP", "Edit"],
        },
      },
      {
        path: "/upload/uploaded-cspr/add",
        name: "add-upload-cspr",
        component: () =>
          import("@/views/Superadmin/Upload/CSPR/UploadCSPRAddNew.vue"),
        meta: {
          pageTitle: "Uploaded Document",
          breadcrumbs: ["Upload Document", "CSPR", "Tambah"],
        },
      },
      {
        path: "/upload/uploaded-cspr/edit/:id",
        name: "edit-upload-cspr",
        component: () =>
          import("@/views/Superadmin/Upload/CSPR/UploadCSPREdit.vue"),
        meta: {
          pageTitle: "Uploaded Document",
          breadcrumbs: ["Upload Document", "CSPR", "Edit"],
        },
      },
      {
        path: "/upload/uploaded-spondon/add",
        name: "add-upload-spondon",
        component: () =>
          import("@/views/Superadmin/Upload/SPONDON/UploadSpondonAddNew.vue"),
        meta: {
          pageTitle: "Uploaded Document",
          breadcrumbs: ["Upload Document", "Sponsorship & Donasi", "Tambah"],
        },
      },
      {
        path: "/upload/uploaded-spondon/edit/:id",
        name: "edit-upload-spondon",
        component: () =>
          import("@/views/Superadmin/Upload/SPONDON/UploadSpondonEdit.vue"),
        meta: {
          pageTitle: "Uploaded Document",
          breadcrumbs: ["Upload Document", "Sponsorship & Donasi", "Edit"],
        },
      },
      {
        path: "/cost-center-manager",
        name: "ccm",
        component: () => import("@/views/Superadmin/CCM/CCMList.vue"),
        meta: {
          pageTitle: "Cost Center Manager",
          breadcrumbs: ["Cost Center Manager"],
        },
      },
      {
        path: "/data-bank",
        name: "data-bank",
        component: () => import("@/views/Superadmin/DataBank/DataBankList.vue"),
        meta: {
          pageTitle: "Data Bank",
          breadcrumbs: ["Data Bank"],
        },
      },
      {
        path: "/admin_document/admin_roadmap/edit/:id",
        name: "admin_roadmap_edit",
        component: () =>
          import(
            "@/views/Superadmin/DataBank/Roadmap/DataBankRoadmapChange.vue"
          ),
        meta: {
          pageTitle: "Data Bank",
          breadcrumbs: ["Data Bank", "Roadmap", "Edit"],
        },
      },
      {
        path: "/admin_document/admin_rkap/edit/:id",
        name: "admin_rkap_edit",
        component: () =>
          import("@/views/Superadmin/DataBank/RKAP/DataBankRKAPChange.vue"),
        meta: {
          pageTitle: "Data Bank",
          breadcrumbs: ["Data Bank", "RKAP", "Edit"],
        },
      },
      {
        path: "/admin_document/admin_cspr/edit/:id",
        name: "admin_cspr_edit",
        component: () =>
          import("@/views/Superadmin/DataBank/CSPR/DataBankCSPRChange.vue"),
        meta: {
          pageTitle: "Data Bank",
          breadcrumbs: ["Data Bank", "CSPR", "Edit"],
        },
      },
      {
        path: "/admin_document/admin_cspr_report/edit/:id",
        name: "admin_cspr_report_edit",
        component: () =>
          import(
            "@/views/Superadmin/DataBank/CSPR_REPORT/DataBankCSPRReportChange.vue"
          ),
        meta: {
          pageTitle: "Data Bank",
          breadcrumbs: ["Data Bank", "CSPR Report", "Edit"],
        },
      },
      {
        path: "/admin_document/admin_spondon/edit/:id",
        name: "admin_spondon_edit",
        component: () =>
          import(
            "@/views/Superadmin/DataBank/SPONDON/DataBankSPONDONChange.vue"
          ),
        meta: {
          pageTitle: "Data Bank",
          breadcrumbs: ["Data Bank", "Sponsorship & Donasi", "Edit"],
        },
      },
      {
        path: "/admin_document/admin_spondon_report/edit/:id",
        name: "admin_spondon_report_edit",
        component: () =>
          import(
            "@/views/Superadmin/DataBank/SPONDON_REPORT/DataBankSPONDONReportChange.vue"
          ),
        meta: {
          pageTitle: "Data Bank",
          breadcrumbs: ["Data Bank", "Sponsorship & Donasi Report", "Edit"],
        },
      },
    ],
  },
  {
    path: "/document",
    redirect: "/document/ikm",
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    meta: {
      middleware: "auth",
    },
    children: [
      // IKM
      {
        path: "/document/ikm",
        name: "ikm",
        component: () => import("@/views/Document/IKM/IKMListing.vue"),
        meta: {
          pageTitle: "Index Kepuasan Masyarakat (IKM)",
          breadcrumbs: ["Documents", "IKM"],
        },
      },
      {
        path: "/document/ikm/add",
        name: "add-ikm",
        component: () => import("@/views/Document/IKM/IKMAddNew.vue"),
        meta: {
          pageTitle: "Index Kepuasan Masyarakat (IKM)",
          breadcrumbs: ["Documents", "IKM", "Tambah"],
        },
      },
      {
        path: "/document/ikm/show/:id",
        name: "show-ikm",
        component: () => import("@/views/Document/IKM/IKMView.vue"),
        meta: {
          pageTitle: "Index Kepuasan Masyarakat (IKM)",
          breadcrumbs: ["Documents", "IKM", "Lihat"],
        },
      },
      // SLI
      {
        path: "/document/sli",
        name: "sli",
        component: () => import("@/views/Document/SLI/SLIListing.vue"),
        meta: {
          pageTitle: "Social Life Index (SLI)",
          breadcrumbs: ["Documents", "SLI"],
        },
      },
      {
        path: "/document/sli/add",
        name: "add-sli",
        component: () => import("@/views/Document/SLI/SLIAddNew.vue"),
        meta: {
          pageTitle: "Social Life Index (SLI)",
          breadcrumbs: ["Documents", "SLI", "Tambah"],
        },
      },
      {
        path: "/document/sli/show/:id",
        name: "show-sli",
        component: () => import("@/views/Document/SLI/SLIView.vue"),
        meta: {
          pageTitle: "Social Life Index (SLI)",
          breadcrumbs: ["Documents", "SLI", "Lihat"],
        },
      },
      // SROI
      {
        path: "/document/sroi",
        name: "sroi",
        component: () => import("@/views/Document/SROI/SROIListing.vue"),
        meta: {
          pageTitle: "Social Return on Investment (SROI)",
          breadcrumbs: ["Documents", "SROI"],
        },
      },
      {
        path: "/document/sroi/add",
        name: "add-sroi",
        component: () => import("@/views/Document/SROI/SROIAddNew.vue"),
        meta: {
          pageTitle: "Social Return on Investment (SROI)",
          breadcrumbs: ["Documents", "SROI", "Tambah"],
        },
      },
      {
        path: "/document/sroi/show/:id",
        name: "show-sroi",
        component: () => import("@/views/Document/SROI/SROIView.vue"),
        meta: {
          pageTitle: "Social Return on Investment (SROI)",
          breadcrumbs: ["Documents", "SROI", "Lihat"],
        },
      },
      // SOSMAP
      {
        path: "/document/sosmap",
        name: "sosmap",
        component: () => import("@/views/Document/SOSMAP/SOSMAPListing.vue"),
        meta: {
          pageTitle: "SOSMAP",
          breadcrumbs: ["Documents", "SOSMAP"],
        },
      },
      {
        path: "/document/sosmap/add",
        name: "add-sosmap",
        component: () => import("@/views/Document/SOSMAP/SOSMAPAddNew.vue"),
        meta: {
          pageTitle: "SOSMAP",
          breadcrumbs: ["Documents", "SOSMAP", "Tambah"],
        },
      },
      {
        path: "/document/sosmap/show/:id",
        name: "show-sosmap",
        component: () => import("@/views/Document/SOSMAP/SOSMAPView.vue"),
        meta: {
          pageTitle: "SOSMAP",
          breadcrumbs: ["Documents", "SOSMAP", "Lihat"],
        },
      },
      // RKAP
      {
        path: "/document/rkap",
        name: "rkap",
        component: () => import("@/views/Document/RKAP/RKAPListing.vue"),
        meta: {
          pageTitle: "RKAP",
          breadcrumbs: ["Documents", "RKAP"],
        },
      },
      {
        path: "/document/rkap/add",
        name: "add-rkap",
        component: () => import("@/views/Document/RKAP/RKAPAddNew.vue"),
        meta: {
          pageTitle: "RKAP",
          breadcrumbs: ["Documents", "RKAP", "Tambah"],
        },
      },
      {
        path: "/document/rkap/show/:id",
        name: "show-rkap",
        component: () => import("@/views/Document/RKAP/RKAPView.vue"),
        meta: {
          pageTitle: "RKAP",
          breadcrumbs: ["Documents", "RKAP", "Lihat"],
        },
      },
      {
        path: "/document/rkap/edit/:id",
        name: "edit-rkap",
        component: () => import("@/views/Document/RKAP/RKAPEdit.vue"),
        meta: {
          pageTitle: "RKAP",
          breadcrumbs: ["Documents", "RKAP", "Edit"],
        },
      },
      // ROADMAP
      {
        path: "/document/roadmap",
        name: "roadmap",
        component: () => import("@/views/Document/ROADMAP/ROADMAPListing.vue"),
        meta: {
          pageTitle: "Roadmap",
          breadcrumbs: ["Documents", "Roadmap"],
        },
      },
      {
        path: "/document/roadmap/add",
        name: "add-roadmap",
        component: () => import("@/views/Document/ROADMAP/ROADMAPAddNew.vue"),
        meta: {
          pageTitle: "Roadmap",
          breadcrumbs: ["Documents", "Roadmap", "Tambah"],
        },
      },
      {
        path: "/document/roadmap/show/:id",
        name: "show-roadmap",
        component: () => import("@/views/Document/ROADMAP/ROADMAPView.vue"),
        meta: {
          pageTitle: "Roadmap",
          breadcrumbs: ["Documents", "Roadmap", "Lihat"],
        },
      },
      {
        path: "/document/roadmap/edit/:id",
        name: "edit-roadmap",
        component: () => import("@/views/Document/ROADMAP/ROADMAPEdit.vue"),
        meta: {
          pageTitle: "Roadmap",
          breadcrumbs: ["Documents", "Roadmap", "Edit"],
        },
      },
      // STAKEHOLDER MAPPING
      {
        path: "/document/stakeholder_mapping",
        name: "stakemap",
        component: () =>
          import("@/views/Document/STAKEMAP/STAKEMAPListing.vue"),
        meta: {
          pageTitle: "Stakeholder Mapping",
          breadcrumbs: ["Documents", "Stakeholder Mapping"],
        },
      },
      {
        path: "/document/stakeholder_mapping/add",
        name: "add-stakemap",
        component: () => import("@/views/Document/STAKEMAP/STAKEMAPAddNew.vue"),
        meta: {
          pageTitle: "Stakeholder Mapping",
          breadcrumbs: ["Documents", "Stakeholder Mapping", "Tambah"],
        },
      },
      {
        path: "/document/stakeholder_mapping/show/:id",
        name: "show-stakemap",
        component: () => import("@/views/Document/STAKEMAP/STAKEMAPView.vue"),
        meta: {
          pageTitle: "Stakeholder Mapping",
          breadcrumbs: ["Documents", "Stakeholder Mapping", "Lihat"],
        },
      },
      {
        path: "/document/stakeholder_mapping/edit/:id",
        name: "edit-stakemap",
        component: () => import("@/views/Document/STAKEMAP/STAKEMAPEdit.vue"),
        meta: {
          pageTitle: "Stakeholder Mapping",
          breadcrumbs: ["Documents", "Stakeholder Mapping", "Edit"],
        },
      },
      // FKM
      {
        path: "/document/fkm",
        name: "fkm",
        component: () => import("@/views/Document/FKM/FKMListing.vue"),
        meta: {
          pageTitle: "FKM",
          breadcrumbs: ["Documents", "FKM"],
        },
      },
      {
        path: "/document/fkm/add",
        name: "add-fkm",
        component: () => import("@/views/Document/FKM/FKMAddNew.vue"),
        meta: {
          pageTitle: "FKM",
          breadcrumbs: ["Documents", "FKM", "Tambah"],
        },
      },
      {
        path: "/document/fkm/show/:id",
        name: "show-fkm",
        component: () => import("@/views/Document/FKM/FKMView.vue"),
        meta: {
          pageTitle: "FKM",
          breadcrumbs: ["Documents", "FKM", "Lihat"],
        },
      },
      // RKS / ECE
      {
        path: "/document/rks_ece",
        name: "rks-ece",
        component: () => import("@/views/Document/RKS_ECE/RKSECEListing.vue"),
        meta: {
          pageTitle: "RKS / ECE",
          breadcrumbs: ["Documents", "RKS / ECE"],
        },
      },
      {
        path: "/document/rks_ece/add",
        name: "add-rks_ece",
        component: () => import("@/views/Document/RKS_ECE/RKSECEAddNew.vue"),
        meta: {
          pageTitle: "RKS / ECE",
          breadcrumbs: ["Documents", "RKS / ECE", "Tambah"],
        },
      },
      {
        path: "/document/rks_ece/show/:id",
        name: "show-rks_ece",
        component: () => import("@/views/Document/RKS_ECE/RKSECEView.vue"),
        meta: {
          pageTitle: "RKS / ECE",
          breadcrumbs: ["Documents", "RKS / ECE", "Lihat"],
        },
      },
      {
        path: "/document/rks_ece/edit/:id",
        name: "edit-rks_ece",
        component: () => import("@/views/Document/RKS_ECE/RKSECEEdit.vue"),
        meta: {
          pageTitle: "RKS / ECE",
          breadcrumbs: ["Documents", "RKS / ECE", "Edit"],
        },
      },
      {
        path: "/document/rks_ece/create_proposal/:id",
        name: "rks-ece-create-proposal",
        component: () =>
          import("@/views/Document/RKS_ECE/RKSECECreateProposal.vue"),
        meta: {
          pageTitle: "RKS / ECE",
          breadcrumbs: ["Documents", "RKS / ECE", "Buat Proposal Vendor"],
        },
      },
      {
        path: "/document/rks_ece/show_proposal/:id",
        name: "show-rks-ece-proposal",
        component: () =>
          import("@/views/Document/RKS_ECE/RKSECEViewProposal.vue"),
        meta: {
          pageTitle: "RKS / ECE",
          breadcrumbs: ["Documents", "RKS / ECE", "Lihat Proposal Vendor"],
        },
      },
      {
        path: "/document/rks_ece/edit_proposal/:id",
        name: "rks-ece-edit-proposal",
        component: () =>
          import("@/views/Document/RKS_ECE/RKSECEEditProposal.vue"),
        meta: {
          pageTitle: "RKS / ECE",
          breadcrumbs: ["Documents", "RKS / ECE", "Edit Proposal Vendor"],
        },
      },
      {
        path: "/document/rks_ece/create_prpo/:id",
        name: "rks-ece-create-prpo",
        component: () =>
          import("@/views/Document/RKS_ECE/RKSECECreatePRPO.vue"),
        meta: {
          pageTitle: "RKS / ECE",
          breadcrumbs: ["Documents", "RKS / ECE", "Buat PR / PO"],
        },
      },
      {
        path: "/document/rks_ece/show_prpo/:id",
        name: "show-rks-ece-prpo",
        component: () => import("@/views/Document/RKS_ECE/RKSECEViewPRPO.vue"),
        meta: {
          pageTitle: "RKS / ECE",
          breadcrumbs: ["Documents", "RKS / ECE", "Lihat PR / PO"],
        },
      },
      {
        path: "/document/rks_ece/edit_prpo/:id",
        name: "rks-ece-edit-prpo",
        component: () => import("@/views/Document/RKS_ECE/RKSECEEditPRPO.vue"),
        meta: {
          pageTitle: "RKS / ECE",
          breadcrumbs: ["Documents", "RKS / ECE", "Edit PR / PO"],
        },
      },
      // AAR
      {
        path: "/document/aar",
        name: "aar",
        component: () => import("@/views/Document/AAR/AARListing.vue"),
        meta: {
          pageTitle: "After Action Review (AAR)",
          breadcrumbs: ["Documents", "AAR"],
        },
      },
      {
        path: "/document/aar/add",
        name: "add-aar",
        component: () => import("@/views/Document/AAR/AARAddNew.vue"),
        meta: {
          pageTitle: "After Action Review (AAR)",
          breadcrumbs: ["Documents", "AAR", "Tambah"],
        },
      },
      {
        path: "/document/aar/show/:id",
        name: "show-aar",
        component: () => import("@/views/Document/AAR/AARView.vue"),
        meta: {
          pageTitle: "After Action Review (AAR)",
          breadcrumbs: ["Documents", "AAR", "Lihat"],
        },
      },
      {
        path: "/document/aar/edit/:id",
        name: "edit-aar",
        component: () => import("@/views/Document/AAR/AAREdit.vue"),
        meta: {
          pageTitle: "After Action Review (AAR)",
          breadcrumbs: ["Documents", "AAR", "Edit"],
        },
      },
      // SPK
      {
        path: "/document/spk",
        name: "spk",
        component: () => import("@/views/Document/SPK/SPKListing.vue"),
        meta: {
          pageTitle: "SPK",
          breadcrumbs: ["Documents", "SPK"],
        },
      },
      {
        path: "/document/spk/add",
        name: "add-spk",
        component: () => import("@/views/Document/SPK/SPKAddNew.vue"),
        meta: {
          pageTitle: "SPK",
          breadcrumbs: ["Documents", "SPK", "Tambah"],
        },
      },
      {
        path: "/document/spk/show/:id",
        name: "show-spk",
        component: () => import("@/views/Document/SPK/SPKView.vue"),
        meta: {
          pageTitle: "SPK",
          breadcrumbs: ["Documents", "SPK", "Lihat"],
        },
      },
      {
        path: "/document/spk/edit/:id",
        name: "edit-spk",
        component: () => import("@/views/Document/SPK/SPKEdit.vue"),
        meta: {
          pageTitle: "SPK",
          breadcrumbs: ["Documents", "SPK", "Edit"],
        },
      },
      {
        path: "/document/spk/generate-template",
        name: "generate-template-spk",
        component: () => import("@/views/Document/SPK/SPKGenerateTemplate.vue"),
        meta: {
          pageTitle: "SPK",
          breadcrumbs: ["Documents", "SPK", "Generate Template"],
        },
      },
      // BAST
      {
        path: "/document/bast",
        name: "bast",
        component: () => import("@/views/Document/BAST/BASTListing.vue"),
        meta: {
          pageTitle: "BAST",
          breadcrumbs: ["Documents", "BAST"],
        },
      },
      {
        path: "/document/bast/add",
        name: "add-bast",
        component: () => import("@/views/Document/BAST/BASTAddNew.vue"),
        meta: {
          pageTitle: "BAST",
          breadcrumbs: ["Documents", "BAST", "Tambah"],
        },
      },
      {
        path: "/document/bast/show/:id",
        name: "show-bast",
        component: () => import("@/views/Document/BAST/BASTView.vue"),
        meta: {
          pageTitle: "BAST",
          breadcrumbs: ["Documents", "BAST", "Lihat"],
        },
      },
      {
        path: "/document/bast/generate-template",
        name: "generate-template-bast",
        component: () =>
          import("@/views/Document/BAST/BASTGenerateTemplate.vue"),
        meta: {
          pageTitle: "BAST",
          breadcrumbs: ["Documents", "BAST", "Generate Template"],
        },
      },
      // SES / SRS
      {
        path: "/document/ses_srs",
        name: "ses_srs",
        component: () => import("@/views/Document/SES_SRS/SESSRSListing.vue"),
        meta: {
          pageTitle: "SES / SRS",
          breadcrumbs: ["Documents", "SES / SRS"],
        },
      },
      {
        path: "/document/ses_srs/add",
        name: "add-ses_srs",
        component: () => import("@/views/Document/SES_SRS/SESSRSAddNew.vue"),
        meta: {
          pageTitle: "SES / SRS",
          breadcrumbs: ["Documents", "SES / SRS", "Tambah"],
        },
      },
      {
        path: "/document/ses_srs/show/:id",
        name: "show-ses_srs",
        component: () => import("@/views/Document/SES_SRS/SESSRSView.vue"),
        meta: {
          pageTitle: "SES / SRS",
          breadcrumbs: ["Documents", "SES / SRS", "Lihat"],
        },
      },
      {
        path: "/document/ses_srs/edit/:id",
        name: "edit-ses_srs",
        component: () => import("@/views/Document/SES_SRS/SESSRSEdit.vue"),
        meta: {
          pageTitle: "SES / SRS",
          breadcrumbs: ["Documents", "SES / SRS", "Edit"],
        },
      },
      // Sponsorship & Donasi
      {
        path: "/document/sponsorship_and_donasi",
        name: "spondon",
        component: () =>
          import("@/views/Document/SPONSORSHIP_DONASI/SPONDONListing.vue"),
        meta: {
          pageTitle: "Sponsorship & Donasi",
          breadcrumbs: ["Documents", "Sponsorship & Donasi"],
        },
      },
      {
        path: "/document/sponsorship_and_donasi/add",
        name: "add-spondon",
        component: () =>
          import("@/views/Document/SPONSORSHIP_DONASI/SPONDONAddNew.vue"),
        meta: {
          pageTitle: "Sponsorship & Donasi",
          breadcrumbs: ["Documents", "Sponsorship & Donasi", "Tambah"],
        },
      },
      {
        path: "/document/sponsorship_and_donasi/show/:id",
        name: "show-spondon",
        component: () =>
          import("@/views/Document/SPONSORSHIP_DONASI/SPONDONView.vue"),
        meta: {
          pageTitle: "Sponsorship & Donasi",
          breadcrumbs: ["Documents", "Sponsorship & Donasi", "Lihat"],
        },
      },
      {
        path: "/document/sponsorship_and_donasi/edit/:id",
        name: "edit-spondon",
        component: () =>
          import("@/views/Document/SPONSORSHIP_DONASI/SPONDONEdit.vue"),
        meta: {
          pageTitle: "Sponsorship & Donasi",
          breadcrumbs: ["Documents", "Sponsorship & Donasi", "Edit"],
        },
      },
      // CSPR
      {
        path: "/document/cspr",
        name: "cspr",
        component: () => import("@/views/Document/CSPR/CSPRListing.vue"),
        meta: {
          pageTitle: "CSPR",
          breadcrumbs: ["Documents", "CSPR"],
        },
      },
      {
        path: "/document/cspr/add",
        name: "add-cspr",
        component: () => import("@/views/Document/CSPR/CSPRAddNew.vue"),
        meta: {
          pageTitle: "CSPR",
          breadcrumbs: ["Documents", "CSPR", "Tambah"],
        },
      },
      {
        path: "/document/cspr/show/:id",
        name: "show-cspr",
        component: () => import("@/views/Document/CSPR/CSPRView.vue"),
        meta: {
          pageTitle: "CSPR",
          breadcrumbs: ["Documents", "CSPR", "Lihat"],
        },
      },
      {
        path: "/document/cspr/edit/:id",
        name: "edit-cspr",
        component: () => import("@/views/Document/CSPR/CSPREdit.vue"),
        meta: {
          pageTitle: "CSPR",
          breadcrumbs: ["Documents", "CSPR", "Edit"],
        },
      },
      // Complain Report
      {
        path: "/document/complain",
        name: "complain",
        component: () =>
          import("@/views/Document/COMPLAIN_REPORT/COMPLAINListing.vue"),
        meta: {
          pageTitle: "Complain Report",
          breadcrumbs: ["Documents", "Complain Report"],
        },
      },
      {
        path: "/document/complain/add",
        name: "add-complain",
        component: () =>
          import("@/views/Document/COMPLAIN_REPORT/COMPLAINAddNew.vue"),
        meta: {
          pageTitle: "Complain Report",
          breadcrumbs: ["Documents", "Complain Report", "Tambah"],
        },
      },
      {
        path: "/document/complain/show/:id",
        name: "show-complain",
        component: () =>
          import("@/views/Document/COMPLAIN_REPORT/COMPLAINView.vue"),
        meta: {
          pageTitle: "Complain Report",
          breadcrumbs: ["Documents", "Complain Report", "Lihat"],
        },
      },
      {
        path: "/document/complain/edit/:id",
        name: "edit-complain",
        component: () =>
          import("@/views/Document/COMPLAIN_REPORT/COMPLAINEdit.vue"),
        meta: {
          pageTitle: "Complain Report",
          breadcrumbs: ["Documents", "Complain Report", "Edit"],
        },
      },
      {
        path: "/document/job-completion",
        name: "job-completion",
        component: () =>
          import("@/views/Document/JOB_COMPLETION/JOBCOMListing.vue"),
        meta: {
          pageTitle: "Job Completion",
          breadcrumbs: ["Documents", "Job Completion"],
        },
      },
      {
        path: "/document/job-completion/add",
        name: "add-jobcom",
        component: () =>
          import("@/views/Document/JOB_COMPLETION/JOBCOMAddNew.vue"),
        meta: {
          pageTitle: "Job Completion",
          breadcrumbs: ["Documents", "Job Completion", "Tambah"],
        },
      },
      {
        path: "/document/job-completion/show/:id",
        name: "show-jobcom",
        component: () =>
          import("@/views/Document/JOB_COMPLETION/JOBCOMView.vue"),
        meta: {
          pageTitle: "Job Completion",
          breadcrumbs: ["Documents", "Job Completion", "Lihat"],
        },
      },
      {
        path: "/document/job-completion/generate-template",
        name: "generate-template-jobcom",
        component: () =>
          import("@/views/Document/JOB_COMPLETION/JOBCOMGenerateTemplate.vue"),
        meta: {
          pageTitle: "Job Completion",
          breadcrumbs: ["Documents", "Job Completion", "Generate Template"],
        },
      },
      // IR CSPR
      {
        path: "/document/ir-cspr",
        name: "ir-cspr",
        component: () => import("@/views/Document/IR_CSPR/IR_CSPRListing.vue"),
        meta: {
          pageTitle: "IR CSPR",
          breadcrumbs: ["Documents", "IR CSPR"],
        },
      },
      {
        path: "/document/ir-cspr/add",
        name: "add-ir-cspr",
        component: () => import("@/views/Document/IR_CSPR/IR_CSPRAddNew.vue"),
        meta: {
          pageTitle: "IR CSPR",
          breadcrumbs: ["Documents", "IR CSPR", "Tambah"],
        },
      },
      {
        path: "/document/ir-cspr/show/:id",
        name: "show-ir-cspr",
        component: () => import("@/views/Document/IR_CSPR/IR_CSPRView.vue"),
        meta: {
          pageTitle: "IR CSPR",
          breadcrumbs: ["Documents", "IR CSPR", "Lihat"],
        },
      },
      {
        path: "/document/ir-cspr/edit/:id",
        name: "edit-ir-cspr",
        component: () => import("@/views/Document/IR_CSPR/IR_CSPREdit.vue"),
        meta: {
          pageTitle: "IR CSPR",
          breadcrumbs: ["Documents", "IR CSPR", "Edit"],
        },
      },
      // IR Sponsorship & Donasi
      {
        path: "/document/ir-spondon",
        name: "ir-spondon",
        component: () =>
          import("@/views/Document/IR_SPONDON/IR_SPONDONListing.vue"),
        meta: {
          pageTitle: "IR Sponsorship & Donasi",
          breadcrumbs: ["Documents", "IR Sponsorship & Donasi"],
        },
      },
      {
        path: "/document/ir-spondon/add",
        name: "add-ir-spondon",
        component: () =>
          import("@/views/Document/IR_SPONDON/IR_SPONDONAddNew.vue"),
        meta: {
          pageTitle: "IR Sponsorship & Donasi",
          breadcrumbs: ["Documents", "IR Sponsorship & Donasi", "Tambah"],
        },
      },
      {
        path: "/document/ir-spondon/show/:id",
        name: "show-ir-spondon",
        component: () =>
          import("@/views/Document/IR_SPONDON/IR_SPONDONView.vue"),
        meta: {
          pageTitle: "IR Sponsorship & Donasi",
          breadcrumbs: ["Documents", "IR Sponsorship & Donasi", "Lihat"],
        },
      },
      {
        path: "/document/ir-spondon/edit/:id",
        name: "edit-ir-spondon",
        component: () =>
          import("@/views/Document/IR_SPONDON/IR_SPONDONEdit.vue"),
        meta: {
          pageTitle: "IR Sponsorship & Donasi",
          breadcrumbs: ["Documents", "IR Sponsorship & Donasi", "Edit"],
        },
      },
      // Dokumentasi Kegiatan
      {
        path: "/document/dokumentasi_kegiatan",
        name: "dokumentasi-kegiatan",
        component: () =>
          import("@/views/Document/Dokumentasi/DokumentasiList.vue"),
        meta: {
          pageTitle: "Dokumentasi Kegiatan",
          breadcrumbs: ["Documents", "Dokumentasi Kegiatan"],
        },
      },
      {
        path: "/document/dokumentasi_kegiatan/add",
        name: "add-dokumentasi-kegiatan",
        component: () =>
          import("@/views/Document/Dokumentasi/DokumentasiAddNew.vue"),
        meta: {
          pageTitle: "Dokumentasi Kegiatan",
          breadcrumbs: ["Documents", "Dokumentasi Kegiatan", "Tambah"],
        },
      },
      {
        path: "/document/dokumentasi_kegiatan/show/:id",
        name: "show-dokumentasi-kegiatan",
        component: () =>
          import("@/views/Document/Dokumentasi/DokumentasiView.vue"),
        meta: {
          pageTitle: "Dokumentasi Kegiatan",
          breadcrumbs: ["Documents", "Dokumentasi Kegiatan", "Lihat"],
        },
      },
      {
        path: "/document/dokumentasi_kegiatan/edit/:id",
        name: "edit-dokumentasi-kegiatan",
        component: () =>
          import("@/views/Document/Dokumentasi/DokumentasiEdit.vue"),
        meta: {
          pageTitle: "Dokumentasi Kegiatan",
          breadcrumbs: ["Documents", "Dokumentasi Kegiatan", "Edit"],
        },
      },
      // monitoring report
      {
        path: "/document/monitoring-report/show/:id",
        name: "show-monitoring_report",
        component: () =>
          import("@/views/Document/MONITORING_REPORT/MonitoringView.vue"),
        meta: {
          pageTitle: "Monitoring Report",
          breadcrumbs: ["Documents", "Monitoring Report", "Lihat"],
        },
      },
      {
        path: "/document/monitoring-report/add/:id",
        name: "add-monitoring_report",
        component: () =>
          import("@/views/Document/MONITORING_REPORT/MonitoringAddNew.vue"),
        meta: {
          pageTitle: "Monitoring Report",
          breadcrumbs: ["Documents", "Monitoring Report", "Tambah"],
        },
      },
      {
        path: "/document/monitoring-report/edit/:id",
        name: "edit-monitoring_report",
        component: () =>
          import("@/views/Document/MONITORING_REPORT/MonitoringEdit.vue"),
        meta: {
          pageTitle: "Monitoring Report",
          breadcrumbs: ["Documents", "Monitoring Report", "Edit"],
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/AuthLayout.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
        meta: {
          pageTitle: "Sign In",
        },
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
        meta: {
          pageTitle: "Password reset",
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/SystemLayout.vue"),
    children: [
      {
        // the 404 route, when none of the above matches
        path: "/404",
        name: "404",
        component: () => import("@/views/crafted/authentication/Error404.vue"),
        meta: {
          pageTitle: "Error 404",
        },
      },
      {
        path: "/500",
        name: "500",
        component: () => import("@/views/crafted/authentication/Error500.vue"),
        meta: {
          pageTitle: "Error 500",
        },
      },
      {
        path: "/maintenance",
        name: "maintenance",
        component: () => import("@/views/Maintenance.vue"),
        meta: {
          pageTitle: "Maintenance",
        },
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  // current page view title
  document.title = `${to.meta.pageTitle} - ${process.env.VUE_APP_NAME}`;

  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  // verify auth token before each page change
  store.dispatch(Actions.VERIFY_AUTH, { api_token: JwtService.getToken() });

  // before page access check if page requires authentication
  if (to.meta.middleware == "auth") {
    if (store.getters.isUserAuthenticated) {
      //next();
      // if current time is 01.00 - 02.00, redirect to maintenance page
      const currentHour = new Date().getHours();
      if (currentHour >= 1 && currentHour <= 2) {
        next({ name: "maintenance" });
      } else {
        next();
      }
    } else {
      next({ name: "sign-in" });
    }
  } else {
    next();
  }

  // Scroll page to top on every route change
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
});

export default router;
