
import { defineComponent, nextTick, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import { themeMode } from "@/core/helpers/config";
import { initializeComponents } from "@/core/plugins/keenthemes";
import wb from "@/registerServiceWorker";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
  name: "app",
  setup() {
    const store = useStore();
    const showUpgradeUI = ref(false);

    onMounted(() => {
      /**
       * Overrides the layout config using saved data from localStorage
       * remove this to use static config (@/core/config/DefaultLayoutConfig.ts)
       */
      store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);

      /**
       *  Sets a mode from configuration
       */
      store.dispatch(Actions.SET_THEME_MODE_ACTION, themeMode.value);

      nextTick(() => {
        initializeComponents();

        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
      });

      // redirect if user not from mobile device
      if (
        !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        window.location.href = process.env.VUE_APP_DESKTOP_VERSION_URL;
      }

      if (wb) {
        wb.addEventListener("waiting", () => {
          Swal.fire({
            title: "Update Aplikasi",
            text: "Versi terbaru sudah tersedia. Silahkan klik tombol update untuk mengunduh versi terbaru.",
            icon: "info",
            showCancelButton: false,
            confirmButtonText: "Update",
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
              wb.messageSW({ type: "SKIP_WAITING" });
            }
          });
        });
      }
    });

    return {
      showUpgradeUI,
    };
  },
});
